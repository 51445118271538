import React, { useState, useEffect } from "react";
import { Button } from "@paljs/ui/Button";
import { InputGroup } from "@paljs/ui/Input";
import { Link, navigate } from "gatsby";
import Layout from "../components/Layouts";
import { useResetPassword } from "../mutations";
import Seo from "../components/SEO";
import Auth, { Group } from "../components/Auth";
import _ from "lodash";
import produce from "immer";
import queryString from "query-string";

const ResetPassword = ({ location }) => {
  const [formData, setFormData] = useState({
    code: "",
    password: "",
    passwordConfirmation: "",
  });

  useEffect(() => {
    if (_.isEmpty(location.search)) return;
    const params = queryString.parse(location.search);
    const { code } = params;
    if (_.isEmpty(code)) return;

    setFormData((f) => {
      return { ...f, code };
    });
  }, [location]);

  const { data, setData: setVariable } = useResetPassword();

  const passwordMatch = () => {
    return formData.passwordConfirmation === formData.password;
  };

  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setFormData(
      produce(formData, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      })
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    if (!passwordMatch()) {
      alert("Passwords are not matching");
      return;
    }
    setVariable({
      variables: {
        ...formData,
      },
    });
  };

  useEffect(() => {
    console.log(data);
    if (_.isEmpty(data)) return;

    navigate("/login");
  }, [data]);

  return (
    <Layout>
      <Auth title="Change Password" subTitle="Please set a new password">
        <Seo title="Change Password" />
        <form onSubmit={onSubmit}>
          <InputGroup fullWidth>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={onInputChange}
              placeholder="New Password"
              required
            />
          </InputGroup>
          <InputGroup fullWidth status={passwordMatch() ? "Basic" : "Danger"}>
            <input
              type="password"
              name="passwordConfirmation"
              value={formData.passwordConfirmation}
              onChange={onInputChange}
              placeholder="Confirm Password"
              required
            />
          </InputGroup>
          <Button status="Success" type="submit" shape="SemiRound" fullWidth>
            Change Password
          </Button>
        </form>
        <Group>
          <Link to="/login">Back to Log In</Link>
        </Group>
      </Auth>
    </Layout>
  );
};

export default ResetPassword;
